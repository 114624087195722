import './Passo.css';

const Passo = () => {
    return (
        <section id='passo' className='container__all'>
            <h2 className='h2__container passo__h2--color'>Passo a Passo</h2>
            <div className='passo__container secondContainer__all'>
                <div className='p__opacity passo__opacity'></div>
                <div className='passo__p--container'>
                    <ol>
                        <li className='passo__li'>Informe quais itens serão contratados acessando o ícone “CONTRATAÇÃO”</li>
                        <li className='passo__li'>Realize o pagamento através do link que enviaremos por email.</li>
                        <li className='passo__li'>Envie o comprovante para nosso email.</li>
                        <li className='passo__li'>(PARA O LANCHE EM KIT) Junto a criança, preencha o formulário que será enviado após a contratação <span className='passo__span'>com as escolhas da semana</span></li>
                    </ol>
                    
                    <a href='https://docs.google.com/forms/d/e/1FAIpQLSez0q92AeQVeU9C5r49EVvnx56P_xGwgVyvOLUVnjAuXDwzqQ/viewform' className='passo__a' target='_blank'>Contratação</a>
                </div>
            </div>
        </section>
    )
}

export default Passo;